<template>
  <div class="salary">
    <div class="error" v-if="showError">服务不可用，请联系管理员</div>
    <div class="page-wrap" v-else>
      <div class="title">{{title}}</div>
      <div class="item" v-for="(column, index) in columns" :key="index">
        <div class="label">{{column}}</div>
        <div class="money">{{formatterNumber(detail.info[column])}}</div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: '',
      detail: [],
      columns: [],
      id: undefined,
      showError: false
    }
  },
  methods: {
    formatterNumber (x) {
      if (x === undefined) {
        return ''
      } else {
        const v = x * 1
        if (typeof v === 'number') {
          return v.toFixed(2)
        } else {
          return x
        }
      }
    },
    getSalaryDetail () {
      const that = this
      that.axios.get('salary/' + this.id).then(function (resp) {
        if (resp.data.code === 0) {
          that.title = resp.data.title
          that.detail = resp.data.detail
          that.columns = resp.data.columns
        }
        if (resp.data.code === 1) {
          that.showError = true
        }
      }).catch(function () {
        that.showError = true
      })
    }
  },
  mounted () {
    if (this.$route.params.id !== undefined) {
      this.id = this.$route.params.id
      this.getSalaryDetail()
    } else {
      this.showError = true
    }
  }
}
</script>

<style scoped>
.salary{
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.page-wrap{
  width: 100%;
  min-height: 100%;
  min-width: 300px;
  max-width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 15px;
}
.title{
  font-size: 20px;
  position: sticky;
  top: 0;
  background-color: white;
  box-sizing: border-box;
  padding: 10px 10px 20px;
  font-weight: 600;
  border-bottom: 1px solid #DCDFE6;
}
.item{
  font-size: 14px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #DCDFE6;
  box-sizing: border-box;
  padding: 10px;
}
.error{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
